.feedback-container {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  color: #000f29;
  font-weight: bold;
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.description {
  color: #00225c;
  text-align: center;
  margin-bottom: 40px;
}
