.reset-password-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  color: #000f29;
  font-weight: bold;
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.description {
  color: #00225c;
  margin-bottom: 40px;
}

.gradientButton {
  @media (min-width: 1000px) {
    width: 30%;
  }

  width: 90%;
}
