.navbar {
  width: 60%;
  margin: auto;
  display: flex;
  align-items: center;

  @media (max-width: 970px) {
    width: 100%;
  }
}

.navbarContainer {
  width: 100%;
  box-shadow: var(--shadow) 0 2px 4px 0;
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(white, #c7d8f4);
}

.logo {
  margin: 10px;
  height: 50px;
}
