input,
input:focus {
  border: none;
  outline: none;
}

input::placeholder {
  color: #d9dfe3;
  font-size: 14px;
}

.customTextInputContainer {
  @media (max-width: 1000px) {
    width: 90%;
  }

  width: 30%;
}
